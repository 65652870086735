import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';

import { ConversationAttributes, SupportedLanguages, WebchatActionType } from '@pwp-common';

import { RoomUser } from '../../../../common/conversation/chat-component/interfaces';
import { sendWebchatAction } from '../../../../common/conversation/send-webchat-action/send-webchat-action';
import { isInIframe } from '../../../../common/global/is-in-iframe/is-in-iframe';
import { locationToken } from '../../../../tokens/location.token';

import { IncomingChatWaitingRoomInterface } from './incoming-chat-waiting-room-interface';

@UntilDestroy()
@Component({
  selector: 'app-incoming-chat-waiting-room',
  templateUrl: './incoming-chat-waiting-room.component.html',
  styleUrls: ['./incoming-chat-waiting-room.component.css'],
})
export class IncomingChatWaitingRoomComponent implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////

  queuePosition: number;

  redirectURL: string;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public ref: MatDialogRef<IncomingChatWaitingRoomComponent>,
    private translocoService: TranslocoService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(locationToken) private location: Location,
    @Inject(MAT_DIALOG_DATA) public data: IncomingChatWaitingRoomInterface,
  ) {}

  ngOnInit(): void {
    this.data.client.$room.pipe(untilDestroyed(this)).subscribe((room) => {
      this.handleUpdatedConversationAttributes(room?.attributes);
    });

    this.data.client.$participants.pipe(untilDestroyed(this)).subscribe((participants) => {
      this.handleUpdatedParticipants(participants);
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Buttons
  /////////////////////////////////////////////////////////////////////////////////////////////

  redirectNow() {
    if (isInIframe()) {
      sendWebchatAction({ type: WebchatActionType.redirectFromChat, url: this.redirectURL });
    } else {
      this.location.assign(this.redirectURL);
    }
  }

  handleUpdatedConversationAttributes(conversationAttributes: ConversationAttributes) {
    this.queuePosition = conversationAttributes?.getWaitingRoom()?.getQueuePosition();
    if (isNil(this.queuePosition) || this.queuePosition === 0) {
      this.ref.close();
      return;
    }
    for (const lang of SupportedLanguages.all) {
      this.translocoService.setTranslationKey(
        'incoming-chat-waiting-room.body',
        conversationAttributes.getWaitingRoom().getWaitingRoomMessage(lang)!,
        lang.getShortCode(),
      );
    }
    this.redirectURL = conversationAttributes.getRedirectURL();
    this.queuePosition = conversationAttributes.getWaitingRoom()?.getQueuePosition();
    this.changeDetectorRef.detectChanges();
  }

  handleUpdatedParticipants(participants: RoomUser[]) {
    if (isNil(participants) || participants.length <= 1) {
      return;
    }
    this.ref.close();
  }
}
